.loader {
  width: 100%;
  aspect-ratio: 1465 / 404;
  background-color: var(--secondary-color);
  padding-bottom: 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    aspect-ratio: 767 / 450;
  }
}
